<template>
    <!-- Modal -->
    <div class="modal fade" id="order" tabindex="-1" role="dialog" aria-labelledby="formsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Generate an Order</h5>
                        <p class="text-muted">Order from all the suppliers on the system.</p>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <status v-model:status_data=status_data></status>

                            <form action="#" @submit.prevent="handleSubmit()">
                                <div class="row" v-if="screen == 0">
                                    <div class="col-12">
                                        <p class="text-orange fw-bold text-center" style="font-size: 1rem">Select everything you want to order.</p>

                                        <div class="card mt-3" v-for="({name, stock_type, description, id}, index) in stock" :key="index"> 
                                            <div class="card-body pb-0"  @click="setFooterIndex(id)">
                                                <div class="d-flex" v-if="stock_ids.indexOf(id) != '-1'">
                                                    <h6 class="ms-auto">
                                                        <font-awesome-icon class="text-orange mb-2 ms-auto" :icon="['fas', 'check']"></font-awesome-icon>
                                                    </h6>
                                                </div>
                                                <div class="d-flex">
                                                    <p class="text-dark"> <span class="fw-bold">Name:</span> {{ name }}</p>
                                                    <p class="text-dark ms-auto">
                                                        <span class="fw-bold">Type:</span> {{ stock_type }}
                                                    </p>
                                                </div>

                                                <p class="text-dark"><span class="fw-bold">Description:</span> {{ description }}</p>
                                            </div>

                                            <div class="card-footer border-0 bg-white" v-if="footer_index == id">
                                                <div class="form-row">
                                                    <label class="mb-2 fw-bold">Quantity</label>
                                                    <div class="input-group">
                                                        <span class="input-group-text">
                                                            <font-awesome-icon id="eye" :icon="['fa', 'boxes']" />
                                                        </span>
                                                        
                                                        <input type="number" v-model="quantity" class="form-control" placeholder="Enter quantity required">

                                                        <button type="button" class="btn btn-dark ms-3 submit-stock" style="padding: 0 1.5rem;" @click="chooseStock(id)"> 
                                                            Go <font-awesome-icon class="fw-bold text-white ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 d-flex mt-4">
                                            <div class="mx-auto">
                                                <button type="button" class="btn btn-dark text-uppercase me-2" @click="loadMore()"> 
                                                    <p class="m-0 ps-2 pe-2" style="letter-spacing: 1px">Load more</p>
                                                </button>

                                                <button class="btn bg-orange text-white text-uppercase" style="letter-spacing: 1px;" type="button" @click="nextScreen()">
                                                    Next <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                                                </button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>

                                <div class="row" v-if="screen == 1">
                                    <status-data class="mt-2" :status_data=status_data></status-data>

                                    <div class="col-12">
                                        <p class="text-orange fw-bold text-center" style="font-size: 1rem">Enter the delivery date for all these items.</p>
                                    </div>
                                    
                                    <div class="form-row">
                                        <div class="input-group">
                                            <input type="date" class="form-control" v-model="data.delivery_date" required style="font-size: 2rem!important;"> 
                                        </div>
                                    </div>

                                    <div class="d-flex">
                                        <button type="button" class="btn btn-secondary text-uppercase mt-4 w-25 me-2" @click="this.screen = 0"> 
                                            <font-awesome-icon :icon="['fas', 'angle-left']"></font-awesome-icon>
                                        </button>

                                        <button class="btn bg-orange text-uppercase text-white mt-4 w-75">
                                            <span class="m-0" style="letter-spacing: 1px">Finish</span> <font-awesome-icon class="ms-3" :icon="['fas', 'check']"></font-awesome-icon>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';
    
    .side-menu {
        @include media("<=tablet") {
            display: none;
        }

        p {
            cursor: pointer;
        }
    }

    .top-menu {
        display: none;

        @include media("<=tablet") {
            display: flex;
        }

        p {
            cursor: pointer;
        }
    } 

    #cards {
        display: block!important;

        .card :before {
            background-color: transparent;
        }

        .card {
            cursor: pointer;

            .big-icons {
                font-size: 5rem;

                &:hover > & {
                    color: $orange-color!important;
                }
            }             
        } 
    }  
    
    .input-group {
        .input-group-text {
            background: #eee;
        }

        .form-control {
            background: #eee;
        }
    }
    
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'OrderModal',
        props: ['modal_data'],
        components: {
            Status
        },
        data() {
            return {
                data: {
                    order_items: [],
                    status: "pending",  
                    delivery_date: null
                },
                stock: [],
                screen: 0,
                status_data: {
                    "success": false,
                    "success_message": 'Form Submission was successful!',
                    "error": false,
                    "error_message": 'There are some errors.',
                    "loading": false
                },
                footer_index: null,
                quantity: null,
                stock_ids: [],
                page: 0,
                size: 10,
                last_id: 1
            }
        },
        mounted() {
            this.getStock()
        },
        methods: {
            handleSubmit() {
                this.status_data.loading = true

                this.axios.post('api/orders', this.data).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'Order Created!'

                    setTimeout(() => {
                        this.screen = 0,
                        this.data = {
                            order_items: [],
                            status: "pending",
                            delivery_date: null,
                        },
                        this.stock_ids = [],
                        this.quantity = null,
                        this.status_data.success = true,
                        this.footer_index = null
                    }, 2000);
                }).catch((error) => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = error.response.data.message
                })
            },
            setFooterIndex(value) {
                if(this.footer_index == value) {
                    this.footer_index = null
                } else {
                    this.footer_index = value
                }
            },
            chooseStock(stock_id) {
                let index = this.stock_ids.indexOf(stock_id)

                if(index == '-1') {
                    this.data.order_items.push({
                        quantity: this.quantity,
                        stock_id: stock_id
                    })

                    this.stock_ids.push(stock_id)
                } else {
                    this.data.order_items[index].quantity = this.quantity
                }

                this.quantity = null
                this.footer_index = null
            },
            getStock() {
                let url = '/api/stocks'

                this.axios.get(url + '?size=' + this.size + '&page=' + this.page + '&last_id=' + this.last_id).then((response) => {
                    this.stock = response.data.data
                    this.last_id = response.data.last_id
                }).catch((error) => {
                    console.log(error);
                })
            },
            nextScreen() {
                this.screen++
            },
            previousScreen() {
                if(this.screen > 0) {
                    this.screen--
                }
            },
            loadMore() {
                this.page = this.page + 1
                this.getStockBySupplier()          
            },
            loadPrevious() {
                if(this.page > 0) {
                    this.page = this.page - 1
                }

                this.getStockBySupplier()
            }
        }
    }
</script>