<template>
    <transition v-if="!loading" name="fade">
        <div id="wrapper">
            <sidebar :page="'Manage Orders'" :sidebar_data=sidebar_data></sidebar>
            <div class="body">
                <div class="backdrop" v-on:click="close()"></div>
                
                <admin-header :title="'Manage Site'" :tagline="'All your controls, in one place.'" v-model:all_screens=all_screens v-model:screen=screen v-model:url=url :user=user style="margin-bottom: 3rem;"></admin-header>

                <div class="container mb-4">
                    <div class="content-wrapper">
                        <div class="content mx-auto">
                            <section-title v-if="title.status == true" :title=title.title :actions=title.actions></section-title>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-group mb-3">
                                        <status v-model:status_data=status_data></status>

                                        <form action="#" @submit.prevent="fetchResults()" style="width: inherit">
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control search" v-model="search" placeholder="Search through system." aria-label="search" aria-describedby="search-submit">

                                                <span class="input-group-text search-icon" id="search-submit">
                                                    <button class="btn btn-transparent" type="submit">
                                                        <font-awesome-icon class="icon" :icon="['fa', 'search']" /> 
                                                    </button>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div id="cards" class="col-md-12 col-lg-12 mt-3" v-for="({id, delivery_date, order_items, supplier, status}, index) in orders" :key="index">
                                    <div class="card mb-2 border-0">
                                        <div class="card-body pb-0">
                                            <div class="d-flex">
                                                <p class="text-dark" v-if="supplier"> <span class="fw-bold">Supplier:</span> {{ supplier.name }}</p>
                                                <p class="text-muted ms-auto text-uppercase fw-bold" style="letter-spacing: .7px">
                                                    Order ID: {{ id }}
                                                </p>
                                            </div>

                                            <div class="bg-secondary mb-4" style="padding-top: 1px"></div>

                                            <div class="row">
                                                <div class="col-md-6 col-sm-12" v-for="({name, quantity, stock_type, description}, index) in order_items" :key="index">
                                                    <p class="text-dark fst-italic">{{ index+1 }}.</p>
                                                    
                                                    <p class="text-dark mb-3"> 
                                                        <span class="fw-bold text-uppercase">
                                                            Name:
                                                        </span> 
                                                        {{ name }}
                                                    </p>

                                                    <p class="text-dark mb-3"> 
                                                        <span class="fw-bold text-uppercase">
                                                            Type:
                                                        </span> 
                                                        {{ stock_type }}
                                                    </p>

                                                    <p class="text-dark mb-3"> 
                                                        <span class="fw-bold text-uppercase">
                                                            Quantity:
                                                        </span> 
                                                        {{ quantity }}
                                                    </p>

                                                    <p class="text-dark mb-3"> 
                                                        <span class="fw-bold text-uppercase">
                                                            Description
                                                        </span> 
                                                        {{ description }}
                                                    </p>
                                                </div>
                                            </div>
                                            
                                            <div class="bg-secondary mb-2" style="padding-top: 1px"></div>

                                            <div class="d-flex mb-2">
                                                <p class="text-muted text-uppercase mb-0 pt-1">
                                                    <span style="letter-spacing: 1px" :class="['text-uppercase', 'fw-bold', 
                                                        {'text-warning': status == 'pending'},
                                                        {'text-primary': status == 'accepted'},
                                                        {'text-danger': status == 'rejected'},
                                                        {'text-info': status == 'fulfilled'},
                                                        {'text-success': status == 'confirmed'}
                                                    ]">{{ status }}</span>
                                                    
                                                    <font-awesome-icon :class="['ms-2', 
                                                        {'text-warning': status == 'pending'},
                                                        {'text-primary': status == 'accepted'},
                                                        {'text-danger': status == 'rejected'},
                                                        {'text-info': status == 'fulfilled'},
                                                        {'text-success': status == 'confirmed'},
                                                    ]" :icon="['fas', 'circle']"></font-awesome-icon>
                                                </p>

                                                <div class="text-muted ms-auto" v-if="status == 'fulfilled'">
                                                    <button class="btn btn-primary text-white btn-sm" @click="updateOrder('confirmed', id)">Confirm Order</button>
                                                </div>

                                                <p class="text-muted ms-auto pt-1" v-if="status != 'fulfilled'">Deliver by: {{ delivery_date }}</p>
                                            </div>
                            
                                        </div>
                                    </div>
                                </div>   

                                <div class="col-md-12 col-lg-12 d-flex mt-4">
                                    <div class="mx-auto">
                                        <button class="btn bg-orange text-white me-2" @click="loadPrevious()" v-if="page > 0"> 
                                            <font-awesome-icon :icon="['fas', 'angle-left']"></font-awesome-icon>
                                        </button>
                                        
                                        <button class="btn bg-orange text-white text-uppercase" v-if="orders.length > 0" @click="loadMore()"> 
                                            <p class="m-0 ps-2 pe-2" style="letter-spacing: 1px">Load more</p>
                                        </button>
                                    </div>
                                </div>  
                            </div>
                        </div>                                          
                    </div>
                </div>
                
                <main-footer></main-footer>
            </div>

            <order-modal :modal_data="{'id': 1}"></order-modal>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_lists.scss';

    #cards {
        display: block!important;
    }
</style>

<script>
import MainFooter from '../../components/Footer'
import Sidebar from '../../components/AutoSidebar'
import AdminHeader from '../../components/Header'
import SectionTitle from '../../components/SectionTitle'
import Status from '../../components/Status'
import OrderModal from '../../components/Modals/OrderModal'
import SidebarData from '../../assets/json/Admin/SidebarData'

export default {
    name: 'AdminOrders',
    components: {
        AdminHeader,
        MainFooter,
        Sidebar,
        SectionTitle,
        Status,
        OrderModal
    },
    data() {
        return {
            orders: [],
            size: 25,
            page: 0,
            last_id: 0,
            status: 'pending',
            title: {status: true, title: 'Your Data', actions: [
                {name: 'Create Order', type: 'modal', action_url: 'order'}
            ]},            
            sidebar_data: SidebarData,  
            all_screens: [
                {title: 'All Orders', screen: 1, url: 'api/orders'}
            ], 
            status_data: {
                "success": false,
                "success_message": 'Form Submission was successful!',
                "error": false,
                "error_message": 'There are some errors.',
                "loading": false
            },            
            screen: 1,
            search: null
        }
    },
    watch: {
        user: function() {
            this.ifNotAdmin()
        }
    },    
    computed: {
        user () {
            return this.$store.state.user
        },
        loading () {
            return this.$store.state.loading
        }       
    },     
    mounted() {
        this.$store.dispatch("getUser")
        this.getDatabyPage()
    },
    methods: {  
        ifNotAdmin() {
            if( !this.user.admin) {
                this.$router.push({name: 'Home'});
            }
        },               
        getDatabyPage() {
            let url = '/api/orders' 

            this.axios.get(url + '?size=' + this.size + '&page=' + this.page + '&last_id=' + this.last_id).then((response) => {
                this.orders = response.data.data
                console.log(this.orders);
                this.last_id = response.data.last_id
            }).catch((errors) => {
                console.log(errors);
            })  
        },
        loadMore() {
            this.page = this.page + 1
            this.getDatabyPage()          
        },
        loadPrevious() {
            if(this.page > 0) {
                this.page = this.page - 1
            }

            this.getDatabyPage()
        },
        updateOrder(value, id) {
            this.status_data.loading = true
            let status = {
                status: value
            }

            this.axios.put('/api/orders/' + id, status).then(() => {
                this.status_data.loading = false
                this.status_data.error = false
                this.status_data.success = true
                this.status_data.success_message = 'Order updated!'

                this.getDatabyPage()
            }).catch((error) => {
                this.status_data.loading = false
                this.status_data.error = true
                this.status_data.success = false
                this.status_data.error_message = 'Order not updated.' + error.response.data.message
            })
        }
    }

}
</script>
